import Vue from 'vue';

const notifications = {
  "created": {
    "title": "Excelente!",
    "message": "Registro creado!",
    "color": "success",
  },

  "updated": {
    "title": "Excelente!",
    "message": "Registro actualizado!",
    "color": "success",
  },

  "deleted": {
    "title": "Excelente!",
    "message": "Registro eliminado!",
    "color": "success",
  },

  "error": {
    "title": "Error!",
    "message": "Ocurrio un error!",
    "color": "danger",
    "type": "error",
  },

  "committed": {
    "title": "Excelente!",
    "message": "Registro publicado!",
    "color": "success",
  },

  "restored": {
    "title": "Excelente!",
    "message": "Registro restaurado!",
    "color": "success",
  }
};

export default class Notifier {

  async commitMessage() {
    return await Vue.prototype.$swal.fire({
      title: "Publicar",
      text: "¿Desea publicar los últimos cambios realizados a este registro?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#2EAE7F",
      cancelButtonColor: "#EB182B",
      confirmButtonText: "Sí, continuar",
      cancelButtonText: "No, cancelar"
    })
  }

  deleteMessage() {
    return Vue.prototype.$swal.fire({
      title: "Eliminar",
      text: "¿Desea eliminar este registro?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2EAE7F",
      cancelButtonColor: "#EB182B",
      confirmButtonText: "Sí, continuar",
      cancelButtonText: "No, cancelar",
      focusConfirm: true,
    });
  }

  async alertMessage(type) {
    return await Vue.prototype.$swal.fire({
      title: notifications[type].title,
      text: notifications[type].message,
      type: notifications[type].type
    });
  }

  async notification(type) {
    return await Vue.prototype.$vs.notify({
      title: notifications[type].title,
      text: notifications[type].message,
      color: notifications[type].color,
      position: "top-right"
    });
  }
}